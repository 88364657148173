import { useState } from "react";
import styled from "styled-components";
import "react-phone-number-input/style.css";
import PhoneInput, { Country, Value } from "react-phone-number-input";

import { isValidPhoneNumber } from "react-phone-number-input";

import intl from "../../services/intl";
import RitualButton from "../global/RitualButton";
import { getStoredUser } from "../../utils/currentUser";
import metrics from "../../utils/metrics";
import { responsive, media } from "../../utils/style";
import { Icons, FlagIcons } from "../../utils/react-svg";
import InfoTooltip from "../InfoTooltip";
import { Color } from "../../utils/styleDesignSystem";

const Container = styled.div`
  background-color: var(--white);
  padding: var(--spacing-1);

  ${responsive.sm`
    padding: var(--spacing-1_5);
  `};

  display: flex;
  flex-direction: column;
  border-radius: 4px;
`;

const Header = styled.h2``;

const SubHeader = styled.p`
  margin-bottom: var(--spacing-1_5);
`;

const MarketingConsentText = styled.p`
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  margin-bottom: 0;
`;

const MarketingOptInCheckbox = styled.input`
  accent-color: var(--indigo-blue, #142b6f);
`;

const MarketingConsentContainer = styled.label`
  display: flex;
  align-items: center;
  padding-left: 4px;
  padding-right: 4px;
  gap: var(--spacing-0_5, 8px);
`;

const MarketingTerms = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-0_5, 8px);
  position: relative;
  svg {
    height: 16px;
    width: 16px;
  }
`;

const SlimIconContainer = styled.div`
  width: 16px;
  svg {
    margin-top: 0 !important;
  }
`;

const SMSForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-1_5);

  input[type="tel"] {
    width: 100%;
    padding: var(--spacing-0_5) var(--spacing-1);
    border-radius: var(--spacing-0_25, 4px);
    border: 1px solid var(--indigo-blue-60, #62719f);
    background: var(--white, #fff);
    color: var(--indigo-blue, #142b6f);
  }
`;

const SuccessMessage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: var(--spacing-0_25, 4px);
  background: var(--success-green, #4c840d);
  padding: var(--spacing-0_75);

  ${responsive.sm`
    align-items: center;
  `};

  svg {
    height: var(--spacing-1);
    width: var(--spacing-1);

    ${media.mobile`
      margin-top: var(--spacing-0_25);
    `};
  }

  p {
    color: var(--white);
    margin-left: var(--spacing-1);
    margin-bottom: 0;
  }
`;

const PhoneNumberInput = styled(PhoneInput)`
  .PhoneInputCountry {
    .PhoneInputCountryIcon {
      margin-top: -10px;
    }
    .PhoneInputCountryIcon--border {
      background: none;
      box-shadow: none;
    }
  }
`;

const TooltipContent = () => (
  <div>
    <p className="typography-caption">
      By signing up for text, you agree to receive recurring automated
      promotional and personalized marketing text messages (e.g. cart reminders)
      at this number from Ritual. Consent is not a condition of any purchase.
      Reply HELP for help and STOP to cancel. Msg frequency varies. Msg & data
      rates may apply. View{" "}
      <a href="/terms-of-service" target="_blank">
        Terms of Service
      </a>{" "}
      and{" "}
      <a href="/privacy-policy" target="_blank">
        Privacy Policy
      </a>
      .
    </p>
  </div>
);

const SMSSignup = ({ country }: { country: Country }) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);
  const [marketingPreference, setMarketingPreference] = useState(true);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const currentUser = getStoredUser();
    if (currentUser) {
      metrics.identify(currentUser.id, {
        phone: phoneNumber,
        smsMarketingOptIn: marketingPreference,
      });
    } else {
      metrics.identify(null, {
        phone: phoneNumber,
        smsMarketingOptIn: marketingPreference,
      });
    }

    metrics.track("SMS Signup", {
      phone: phoneNumber,
      smsMarketingOptIn: marketingPreference,
    });

    setShowSuccessMessage(true);
  };

  const handlePhoneNumberChange = (value: Value) => {
    setPhoneNumber(value);

    if (value && isValidPhoneNumber(value)) {
      setIsPhoneNumberValid(true);
    } else {
      setIsPhoneNumberValid(false);
    }
  };

  return (
    <Container>
      <Header className="typography-lead2">Get Order Updates</Header>
      <SubHeader className="typography-body2 font-dutch">
        We’ll send you updates about your order via SMS.
      </SubHeader>
      <SMSForm onSubmit={handleSubmit}>
        <PhoneNumberInput
          data-testid="phone-number-input"
          placeholder={intl.t("checkout.confirmation.sms-signup.placeholder")}
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
          maxLength={15}
          defaultCountry={country}
          countries={["US", "CA", "GB"]}
          addInternationalOption={false}
          flagComponent={({ country }: { country: string }) => {
            switch (country) {
              case "CA":
                return <FlagIcons.FlagCA data-testid="flag-CA" />;
              case "GB":
                return <FlagIcons.FlagGB data-testid="flag-UK" />;
              default:
                return <FlagIcons.FlagUS data-testid="flag-US" />;
            }
          }}
        />

        <MarketingConsentContainer>
          <MarketingOptInCheckbox
            type="checkbox"
            checked={marketingPreference}
            onChange={(e) => setMarketingPreference(e.target.checked)}
          />
          <MarketingConsentText className="typography-label3 font-circular">
            Yes, text me with news and offers.
          </MarketingConsentText>
          <MarketingTerms>
            <InfoTooltip
              color={Color.indigoBlue60}
              hoverColor={Color.indigoBlue}
              content={<TooltipContent />}
              placement="bottom-start"
              additionalProps={{ offset: [-12, 12] }}
            />
          </MarketingTerms>
        </MarketingConsentContainer>
        <RitualButton
          disabled={!isPhoneNumberValid || showSuccessMessage}
          aria-disabled={!isPhoneNumberValid || showSuccessMessage}
          onClick={handleSubmit}
          className="fullwidth"
          isLink={false}
        >
          Submit
        </RitualButton>
        {showSuccessMessage && (
          <SuccessMessage>
            <SlimIconContainer>
              <Icons.CheckSlim />
            </SlimIconContainer>
            <p className="typography-body3 font-circular">
              {intl.t("checkout.confirmation.sms-signup.success")}{" "}
              {intl.t("checkout.confirmation.sms-signup.success-copy")}
            </p>
          </SuccessMessage>
        )}
      </SMSForm>
    </Container>
  );
};
export default SMSSignup;
