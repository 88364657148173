import styled from "styled-components";
import { Icons } from "../../utils/react-svg";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: var(--spacing-1_5);

  @media (min-width: 750px) {
    justify-content: flex-start;
  }
`;

const Icon = styled.div`
  background-color: var(--white);
  border-radius: 50%;
  height: var(--spacing-3);
  width: var(--spacing-3);
  padding: var(--spacing-0_75);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--spacing-0_5);
  flex: 0 0 var(--spacing-3);

  &.warm {
    svg {
      path {
        fill: var(--warm-60) !important;
      }
    }
  }

  @media (min-width: 750px) {
    margin-bottom: 0;
    margin-right: var(--spacing-0_75);
  }
`;

const ThreeDots = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  margin-top: 24px;

  @media (min-width: 750px) {
    display: none;
  }
`;

const ShippingMilestone = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 80px;

  @media (min-width: 750px) {
    flex-direction: row;
    margin-right: var(--spacing-2);

    &:last-child {
      margin-right: 0;
    }
  }

  p {
    color: var(--indigo-blue-60);
  }
`;

const MobileCaptions = styled.div`
  @media (min-width: 750px) {
    display: none;
  }

  h2,
  p {
    margin-bottom: 0 !important;
  }
`;

const DesktopCaptions = styled.h2`
  display: none;
  margin-bottom: 0;
  white-space: nowrap;

  @media (min-width: 750px) {
    display: block;
  }
`;

interface PizzaTrackerProps {
  processedDate: string;
  shipDate: string;
  deliveryDate: string;
}

const PizzaTracker = ({
  processedDate,
  shipDate,
  deliveryDate,
}: PizzaTrackerProps) => {
  return (
    <Container>
      <ShippingMilestone data-testid="paid-on">
        <Icon aria-hidden="true">
          <Icons.Receipt />
        </Icon>
        <MobileCaptions aria-hidden="true">
          <h2 className="typography-label3">Paid</h2>
          <p className="typography-caption">on {processedDate}</p>
        </MobileCaptions>
        <DesktopCaptions className="typography-body2 font-circular">
          Paid on {processedDate}
        </DesktopCaptions>
      </ShippingMilestone>
      <ThreeDots aria-hidden="true">
        <Icons.ThreeDots />
      </ThreeDots>
      <ShippingMilestone data-testid="ships-on">
        <Icon className="warm" aria-hidden="true">
          <Icons.Freeshipping />
        </Icon>
        <MobileCaptions aria-hidden="true">
          <h2 className="typography-label3">Ships</h2>
          <p className="typography-caption">on {shipDate}</p>
        </MobileCaptions>
        <DesktopCaptions className="typography-body2 font-circular">
          Ships on {shipDate}
        </DesktopCaptions>
      </ShippingMilestone>
      <ThreeDots aria-hidden="true">
        <Icons.ThreeDots />
      </ThreeDots>
      <ShippingMilestone data-testid="delivers-on">
        <Icon className="warm" aria-hidden="true">
          <Icons.Delivered />
        </Icon>
        <MobileCaptions aria-hidden="true">
          <h2 className="typography-label3">Est. Delivery</h2>
          <p className="typography-caption">by {deliveryDate}</p>
        </MobileCaptions>
        <DesktopCaptions className="typography-body2 font-circular">
          Est. Delivery by {deliveryDate}
        </DesktopCaptions>
      </ShippingMilestone>
    </Container>
  );
};

export default PizzaTracker;
