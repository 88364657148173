import styled from "styled-components";
import Cart from "../../store/cart/model";
import CartProduct from "../../store/cart-product/model";
import LedgerItems from "./LedgerItems";
import LedgerReceipt from "./LedgerReceipt";
import { useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";
import { Icons } from "../../utils/react-svg";
import LedgerCouponGiftCards from "./LedgerCouponGiftCards";
import { responsive } from "../../utils/style";

const CartLedgerWrapper = styled.div<{ $showMarginMobile?: boolean }>`
  background-color: var(--white);
  padding: var(--spacing-1);
  gap: var(--spacing-1_5);

  display: flex;
  flex-direction: column;
  border-radius: 4px;

  &.collapsed {
    gap: var(--spacing-0_5);
  }

  ${responsive.sm`
    padding: var(--spacing-1_5);
  `}

  @media (max-width: 932px) {
    margin-left: ${(props) =>
      props.$showMarginMobile ? "inherit" : `calc(var(--grid-margin-xs) * -1)`};
    margin-right: ${(props) =>
      props.$showMarginMobile ? "inherit" : `calc(var(--grid-margin-xs) * -1)`};
  }

  @media (min-width: 933px) {
    padding-top: var(--spacing-1_5);
  }
`;

const Title = styled.h2`
  display: flex;
  justify-content: space-between;
  margin: 0;

  span.show,
  span.hide {
    transition: transform 0.3s;
  }

  span.show {
    transform: rotateX(180deg);
  }
`;

const ShippingAddress = styled.div`
  p {
    margin-bottom: 0;
    line-height: 20px;
  }
`;
interface CartLedgerProps {
  activeCart: Cart;
  activeCartProducts: CartProduct[];
  isProcessing: boolean;
  activeCoupon?: any;
  appliedPromotion?: any;
  hideCouponGiftCards?: boolean;
  showMarginMobile?: boolean;
  shippingAddress?: any; //type this
  firstName?: string | null;
  lastName?: string | null;
}

const CartLedger = ({
  activeCart,
  activeCartProducts,
  activeCoupon,
  appliedPromotion,
  isProcessing,
  hideCouponGiftCards,
  showMarginMobile = false,
  shippingAddress,
  firstName,
  lastName,
}: CartLedgerProps) => {
  const [collapsed, setCollapsed] = useState(false);
  const [canCollapse, setCanCollapse] = useState(false);

  const handleCollapse = () => {
    if (!canCollapse) return;

    setCollapsed(!collapsed);
  };

  const checkWindowSize = useCallback(() => {
    const currentWidth = window.innerWidth;
    if (currentWidth <= 932) {
      setCanCollapse(true);
      setCollapsed(true);
    } else {
      setCanCollapse(false);
      setCollapsed(false);
    }
  }, []);

  useEffect(() => {
    let previousWidth = window.innerWidth;

    const debouncedCheckWindowSize = debounce(() => {
      const currentWidth = window.innerWidth;
      // Only run the resize logic if the width has actually changed
      if (currentWidth !== previousWidth) {
        checkWindowSize();
        previousWidth = currentWidth;
      }
    }, 200);

    checkWindowSize();
    if (window.innerWidth <= 932) {
      setCollapsed(true);
    }

    window.addEventListener("resize", debouncedCheckWindowSize);

    return () => {
      window.removeEventListener("resize", debouncedCheckWindowSize);
    };
  }, [checkWindowSize]);

  return (
    <>
      <CartLedgerWrapper
        className={collapsed ? "collapsed" : ""}
        $showMarginMobile={showMarginMobile}
      >
        <Title
          className="typography-eyebrow2"
          onClick={canCollapse ? handleCollapse : undefined}
          role={canCollapse ? "button" : undefined}
          aria-expanded={canCollapse ? !collapsed : undefined}
          tabIndex={canCollapse ? 0 : -1}
          aria-hidden={!canCollapse}
          onKeyDown={(e) => {
            if (canCollapse && (e.key === "Enter" || e.key === " ")) {
              handleCollapse();
            }
          }}
          style={{ cursor: canCollapse ? "pointer" : "default" }}
        >
          <span>Order Summary</span>
          {canCollapse && (
            <span className={collapsed ? "show" : "hide"}>
              <Icons.CaretDown />
            </span>
          )}
        </Title>
        <LedgerItems
          activeCartProducts={activeCartProducts}
          collapsed={collapsed}
        />
        {!collapsed && !hideCouponGiftCards && (
          <LedgerCouponGiftCards activeCart={activeCart} />
        )}
        <LedgerReceipt
          activeCart={activeCart}
          activeCartProducts={activeCartProducts}
          activeCoupon={activeCoupon}
          appliedPromotion={appliedPromotion}
          isProcessing={isProcessing}
          collapsed={collapsed}
        />
        {shippingAddress && !collapsed && (
          <ShippingAddress>
            <h4 className="typography-label3">Shipped to</h4>
            <p className="typography-body3 font-circular">
              {firstName} {lastName}
            </p>
            <p className="typography-body3 font-circular">
              {shippingAddress.line1}
              {shippingAddress.line2 && (
                <span>{` ${shippingAddress.line2}`}</span>
              )}
            </p>
            <p className="typography-body3 font-circular">
              {shippingAddress.city}, {shippingAddress.state}{" "}
              {shippingAddress.postal_code}
            </p>
          </ShippingAddress>
        )}
      </CartLedgerWrapper>
    </>
  );
};

export default CartLedger;
