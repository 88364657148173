import dayjs from "dayjs";
import { Locale } from "../../../../config/locales";

export const estimateShippingAndDeliveryDates = (
  locale: Locale,
  startingDate: Date,
) => {
  const processedDate = dayjs(startingDate);
  const { shipBusinessDays, deliveryDays } = locale;

  const shipDate = processedDate.add(shipBusinessDays, "day");
  const deliveryDate = processedDate.add(deliveryDays, "day");

  return {
    processedDate: processedDate.format("MM/DD"),
    shipDate: shipDate.format("MM/DD"),
    deliveryDate: deliveryDate.format("MM/DD"),
  };
};
