import { useMemo } from "react";
import styled from "styled-components";

const Column = styled.div<{
  xsOrder?: number;
  sOrder?: number;
  mOrder?: number;
}>`
  flex: 1 1;

  ${({ xsOrder }) =>
    xsOrder !== undefined &&
    `
    @media (max-width: 576px) {
      order: ${xsOrder};
    }
  `}

  ${({ sOrder }) =>
    sOrder !== undefined &&
    `
    @media (min-width: 750px) and (max-width: 932px) {
      order: ${sOrder};
    }
  `}

  ${({ mOrder }) =>
    mOrder !== undefined &&
    `
    @media (min-width: 933px) {
      order: ${mOrder};
    }
  `}

  &.hide-xs {
    @media (max-width: 576px) {
      display: none;
    }
  }

  &.hide-s {
    @media (min-width: 750px /* Grid S Tablet */) and (max-width: 932px) {
      display: none;
    }
  }

  &.hide-m {
    @media (min-width: 933px) {
      display: none;
    }
  }

  &.show-s {
    @media (min-width: 750px /* Grid S Tablet */) and (max-width: 932px) {
      display: block;
    }
  }

  &.show-xs {
    @media (max-width: 576px) {
      display: block;
    }
  }

  &.show-m {
    @media (min-width: 933px) {
      display: block;
    }
  }
`;

interface XSProp {
  size: "0" | 0 | 1 | 2 | 3 | 4 | 5 | 6;
  hide?: boolean;
  order?: number;
}

interface SProp {
  size: "0" | 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  hide?: boolean;
  order?: number;
}

interface MProp {
  size: "0" | 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  hide?: boolean;
  order?: number;
}

type GridColumnProps = {
  children?: React.ReactNode;
  xs: XSProp;
  s: SProp;
  m: MProp;
  addClass?: string;
};

const GridColumn = ({ children, xs, s, m, addClass }: GridColumnProps) => {
  const classNameString = useMemo(() => {
    const classNames = {
      [`r-grid-column-xs-${xs?.size}`]: Boolean(xs?.size),
      [`r-grid-column-s-${s?.size}`]: Boolean(s?.size),
      [`r-grid-column-m-${m?.size}`]: Boolean(m?.size),
    };

    return Object.entries(classNames)
      .filter(([_, value]) => value)
      .map(([key, _]) => key)
      .join(" ");
  }, [xs, s, m]);

  const hideClassNameString = useMemo(() => {
    const classNames = {
      "hide-xs": Boolean(xs?.hide),
      "hide-s": Boolean(s?.hide),
      "hide-m": Boolean(m?.hide),
      "show-xs": !xs?.hide && Boolean(xs?.size),
      "show-s": !s?.hide && Boolean(s?.size),
      "show-m": !m?.hide && Boolean(m?.size),
    };

    return Object.entries(classNames)
      .filter(([_, value]) => value)
      .map(([key, _]) => key)
      .join(" ");
  }, [xs, s, m]);

  return (
    <Column
      className={`${classNameString} ${hideClassNameString}`}
      xsOrder={xs?.order}
      sOrder={s?.order}
      mOrder={m?.order}
    >
      {children}
    </Column>
  );
};

export default GridColumn;
